<template>
  <div>
    <div id="watermark">
      <van-form>
        <div class="info">
          <van-cell class="info-title">
            <template #title>
              <div class="blue-box"></div>
              <span v-if="control.disabled" class="form-title-view">居民信息</span>
              <span v-if="!control.disabled" class="form-title-view">编辑</span>
            </template>
            <template #default>
              <van-uploader :readonly="control.disabled" :after-read="cardIdIdentified">
                <div>
                  <van-icon size="25" :color="control.disabled ? '#ccc' : '#3095FF'" name="photograph" />
                </div>
              </van-uploader>
            </template>
          </van-cell>
          <van-uploader :readonly="control.disabled" :after-read="headImgButton">
            <van-cell-group>
              <van-cell title="照片" :is-link="!control.disabled" :readonly="control.disabled">
                <template>
                  <img class="headImg" :src="show.headImg" alt="">
                </template>
              </van-cell>
            </van-cell-group>
          </van-uploader>
          <van-cell class="required" readonly clickable name="证件类型" :value="show.idNumberTypeStr"
            :value-class="{ 'value-common': show.idNumberTypeStr == '请选择' }" title="证件类型" placeholder="点击选择证件类型"
            @click="showIdNumberType" :is-link="!control.disabled" />
          <van-popup v-model="control.idNumberType" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="selectList.idNumberTypeList" @confirm="idNumberType"
              @cancel="control.idNumberType = false" />
          </van-popup>
          <van-cell title="证件号" class="required">
            <van-field clearable :readonly="control.disabled" @blur="getInfoByIdNumber" v-model="dataForm.idNumber"
              name="证件号" placeholder="请输入有效证件号" :rules="[{ required: true }]" />
          </van-cell>
          <van-cell title="姓名" class="required">
            <van-field clearable clear-trigger="always" :readonly="control.disabled" v-model="dataForm.userName"
              name="姓名" placeholder="请输入姓名或者拍照识别" :rules="[{ required: true }]" />
          </van-cell>
          <van-cell name="性别" title="性别" :value-class="{ 'value-common': dataForm.sex == '请选择' }"
            :value="dataForm.sex == 1 ? '男' : '女'" :rules="[{ required: true, message: '请选择性别' }]"
            @click="control.sex = !control.disabled" readonly clickable placeholder="请选择性别"
            :is-link="!control.disabled" />
          <van-popup v-model="control.sex" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="[{ label: '男', value: 1 }, { label: '女', value: 2 }]"
              @confirm="sexConfirm" @cancel="control.sex = false" />
          </van-popup>

          <van-cell class="required" readonly clickable :value-class="{ 'value-common': show.nationalityStr == '请选择' }"
            :value="show.nationalityStr" name="国籍" title="国籍" placeholder="请选择国籍" @click="goNationality"
            :rules="[{ required: true }]" :is-link="!control.disabled" />

          <van-cell class="required" readonly clickable :value="show.nationStr"
            :value-class="{ 'value-common': show.nationStr == '请选择' }" name="民族" title="民族" placeholder="请选择民族"
            @click="goNation" :rules="[{ required: true }]" :is-link="!control.disabled" />

          <van-cell readonly clickable name="出生日期" :value="dataForm.birthday" title="出生日期" placeholder="请选择出生日期"
            :value-class="{ 'value-common': dataForm.birthday == '请选择' }" :rules="[{ required: true }]"
            @click="showBirthday" :is-link="!control.disabled" />
          <van-calendar v-model="control.birthday" :show-confirm="false" color="#1989fa"
            :default-date="new Date(1997, 0, 1)" :min-date="new Date(1945, 0, 1)" :max-date="new Date()"
            @confirm="birthdayCalendar" />
          <van-cell title="手机号码" class="required">
            <template #title>
              <span class="custom-title">手机号码</span>
            </template>
            <van-field clearable :readonly="control.disabled" v-model="dataForm.mobile" name="手机号码"
              placeholder="请输入手机号码" :rules="[{ required: true }]" />
          </van-cell>

          <van-cell readonly clickable name="与本人关系" :value="show.relationship" :is-link="!control.disabled && dataForm.relationship != 1"
            :value-class="{ 'value-common': show.relationship == '请选择' }" title="与本人关系" placeholder="请选择与本人关系"
            @click="control.relationship = (dataForm.relationship != 1)" />
          <van-popup v-model="control.relationship" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="selectList.relationshipList"
              @confirm="relationshipConfirm($event)" @cancel="control.relationship = false" />
          </van-popup>

          <template v-if="dataForm.id && dataForm.relationship == 10">
            <van-field name="隔间房号" label="隔间房号" v-model="dataForm.room" placeholder="请输入隔间房号"
              clearable clear-trigger="always" />

            <van-field name="合同开始时间" label="合同开始时间" v-model="dataForm.rentStartDate" placeholder="请选择合同开始时间"
              readonly clickable is-link @click="control.rentStartDate = true" />
            <van-popup v-model="control.rentStartDate" position="bottom">
              <van-datetime-picker @cancel="control.rentStartDate = false" @confirm="startDateChange" type="date"
                title="选择年月日" :min-date="defaultDate" :max-date="new Date()" />
            </van-popup>

            <van-field name="合同结束时间" label="合同结束时间" v-model="dataForm.rentFinishDate" placeholder="请选择合同结束时间"
              readonly clickable is-link @click="control.rentFinishDate = true" />
            <van-popup v-model="control.rentFinishDate" position="bottom">
              <van-datetime-picker @cancel="control.rentFinishDate = false" @confirm="endDateChange" type="date" title="选择年月日"
                :min-date="defaultDate" :max-date="new Date()" />
            </van-popup>
          </template>


          <van-cell-group class="info-second">
            <van-cell class="info-title">
              <template #title>
                <div class="blue-box"></div>
                <span class="custom-title">户籍信息</span>
              </template>
            </van-cell>
            <van-cell>
              <template #title>
                <span class="custom-title">户籍地址</span>
              </template>

              <van-field clearable :readonly="control.disabled" v-model="dataForm.regAddress" type="textarea" autosize
                name="户籍地址" placeholder="请输入户籍地址" />
            </van-cell>
            <van-cell title="现居地">
              <template #title>
                <span class="custom-title">现居地址</span>
              </template>
              <van-field clearable :readonly="control.disabled" v-model="dataForm.nowAddress" name="现居地" type="textarea"
                autosize placeholder="请输入现居地址" />
            </van-cell>
            <van-cell class="required" readonly clickable name="户籍类型" :value="show.registryTypeStr" title="户籍类型"
              :value-class="{ 'value-common': show.registryTypeStr == '请选择' }" placeholder="请选择户籍类型"
              :rules="[{ required: true }]" @click="showRegistryType" :is-link="!control.disabled">
              <template #title>
                <span class="custom-title">户籍类型</span>
                <van-icon v-if="show.registryTypeStr != '请选择'" @click.stop="showHouseTypeDes = !showHouseTypeDes"
                  style="margin-left: 15px" name="question-o" size=".5rem" color="#ccc" />
              </template>
            </van-cell>
            <van-popup v-model="control.registryType" position="bottom">
              <van-picker show-toolbar value-key="label" :columns="selectList.registryTypeList" @confirm="registryType"
                @cancel="control.registryType = false" />
            </van-popup>
          </van-cell-group>
          <div class="houseTypeDes" v-if="showHouseTypeDes">
            {{ this.registryContent1[show.registryTypeStr] }}
          </div>
          <van-cell-group class="info-second">
            <van-collapse v-model="collapse">
              <van-collapse-item name="1">
                <template #title>
                  <div class="blue-box"></div>
                  <span class="form-title-view">附属信息</span>
                </template>
                <van-cell title="工作单位">
                  <van-field clearable :readonly="control.disabled" v-model="dataForm.company" name="工作单位"
                    placeholder="请输入工作单位" />
                </van-cell>
                <van-cell title="紧急联系人">
                  <van-field clearable :readonly="control.disabled" v-model="dataForm.emContact" name="紧急联系人"
                    placeholder="请输入紧急联系人" />
                </van-cell>
                <van-cell title="联系人手机号">
                  <van-field clearable :readonly="control.disabled" v-model="dataForm.emMobile" name="联系人手机号"
                    placeholder="请输入联系人手机号" />
                </van-cell>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>

          <div class="userRes"></div>
        </div>

        <van-cell style="background-color: #F5F5F5" />

        <div class="form-btn-view" v-if="control.disabled">
          <van-row class="btns">
            <van-col span="24">
              <van-button type="info" size="large" round @click="redact">编辑</van-button>
            </van-col>
          </van-row>
        </div>
        <div class="form-btn-view" v-else>
          <van-row class="btns">
            <van-col span="24">
              <van-button type="info" size="large" round @click="onSubmit">完成</van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>


    </div>
    <nation v-if="control.nationShow" @setNation="setNation" />
    <nationality v-if="control.nationalityShow" @setNationality="setNationality" />
  </div>

</template>

<script>
import { mapState } from "vuex";
import { getImageStream } from "@/utils/index";
import { formatterDate } from "@/utils/utils";
import { getBirthdayFromIdCard } from "@/utils/utils";
import { getSexFromIdCard } from "@/utils/utils";
import { IdentityCodeValid } from "@/utils/utils";
import { upload } from "@/utils/common"
import { getVirtualDict, getDictTree } from "@/utils/common"
import nation from "@/components/nation/nation";
import nationality from "@/components/nation/nationality";

var that
export default {
  name: "info",
  components: {
    nation,
    nationality
  },
  data() {
    return {
      collapse: ['1'],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      registryContent1: {
        '人户一致': '人户一致: 居民居住的房子和户籍地址相同。',
        '人在户不在': '人在户不在: 常住人口类型之一，没有在本社区落户，例如房东或业主买了本社区的房子，户口未迁过去。',
        '户在人不在': '户在人不在: 常住人口类型之一，例如房东或业主落户，但是未入住的。',
        '集体户(人户均在)': '人户均在本村社。',
        '集体户(户在人不在)': '户在本村社，人不在本村社居住。',
        '人户均不在': '人户均不在: 流动人口类型之一，例如投资客。',
        '本地租客': '本地租客: 本市户口的租客。',
        '其他人员': '其他人员: 组织关系挂靠，例如党员或退休关系挂靠。'
      },
      showHouseTypeDes: false,
      relationship: '',
      relationshipStr: '',
      defaultDate: new Date(2013, 0, 1),
      houseResId: '',
      dataForm: {
        id: '',
        orgId: '',
        userName: '',
        sex: '',
        nationality: '59',
        idNumber: '',
        idNumberType: 1,
        mobile: '',
        nation: '2',
        birthday: '',
        company: '',
        regAddress: '',
        nowAddress: '',
        emContact: '',
        emMobile: '',
        type: '',
        houseId: '',
        temporaryHeadImg: "",
        temporaryHeadImgSuffix: "",
        room: '',
        relationship: '',
        registryType: '',
        rentStartDate: '',
        rentFinishDate: ''
      },
      show: {
        idNumberTypeStr: "身份证",
        nationalityStr: "中国",
        nationStr: "汉族",
        headImg: getImageStream("files/wx/images/content/headImg.png"),//展示头像图
        registryTypeStr: "请选择",
        relationship: '请选择',
        marriage: '请选择',
        spouseIdNumberType: '请选择',
        education: '请选择',
        political: '请选择',
        religious: '请选择',
        employment: '请选择',
        live: '请选择'
      },
      control: {
        disabled: false,
        idNumberType: false,
        birthday: false,
        subareaPopup: false,
        houseTreeCascader: false,
        relationship: false,
        useType: false,
        psychosis: false,
        registryType: false,
        sex: false,
        live: false,
        marriage: false,
        spouseIdNumberType: false,
        education: false,
        religious: false,
        political: false,
        employment: false,
        nationShow: false,
        nationalityShow: false,
        slowVisible: true,
        policyVisible: true,
        prescribeVisible: false,
        diagnosisVisible: false,
        signVisible: false,
        testVisible: false,
        oldShow: false,
        rentStartDate: false,
        rentFinishDate: false
      },
      selectList: {
        idNumberTypeList: [],
        houseTree: [],
        hometownList: [],
        spouseIdNumberTypeList: [],
        marriageList: [],
        educationList: [],
        political: [],
        politicalList: [],
        religiousList: [],
        relationshipList: []
      }
    }
  },
  watch: {
    'dataForm.idNumber'(value, oldVal) {
      if (that.dataForm.idNumberType == 1) {
        if (value.length == 18 || value.length == 15) {
          that.dataForm.birthday = getBirthdayFromIdCard(value)
          that.dataForm.sex = getSexFromIdCard(value)
          that.show.sex = that.dataForm.sex == 1 ? '男' : '女'
        }
      }
    }
  },
  computed: { ...mapState(['nationality', 'nation']) },
  created() {
    that = this
    let options = this.$route.query
    this.dataForm.id = parseInt(options.userId) || ''
    this.dataForm.houseId = options.houseId
    this.dataForm.relationship = options.relationship
    this.show.relationship = options.relationshipStr
    this.init()
  },
  methods: {
    setNation(value) {
      if (value) {
        this.dataForm.nation = value.value
        this.show.nationStr = value.label
      }
      this.control.nationShow = false
    },
    setNationality(value) {
      this.dataForm.nationality = value.value
      this.show.nationalityStr = value.label
      this.control.nationalityShow = false
    },
    init() {
      this.selectInfo()
      this.dataForm.orgId = this.$orgId
      if (this.dataForm.id) {
        this.control.disabled = true
        this.dataInfo()
      }
    },
    submit() {


      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '提交中...',
      })
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/save`),
        method: 'post',
        data: this.$http.adornData(this.dataForm)
      }).then(({ data }) => {
        if (data.code == 0) {
          this.control.disabled = true
          this.dataForm.id = data.userId
        }
      })
    },
    dataInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/my/jmggDetailInfo`),
        method: 'post',
        params: this.$http.adornParams({
          userId: this.dataForm.id,
          orgId: this.dataForm.orgId,
          houseId: this.dataForm.houseId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.dataFormInfo(data.userInfoEntity)
        }
      })
    },
    getInfoByIdNumber() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/userByIdNumber`),
        method: 'get',
        params: this.$http.adornParams({
          idNumber: this.dataForm.idNumber
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          if (data.userInfoList && data.userInfoList.length > 0) this.dataFormInfo(data.userInfoList[0])
        }
      })
    },
    dataFormInfo(info) {
      // 用户基本信息
      that.dataForm.id = info.id || 0
      that.dataForm.userName = info.name || ""
      that.dataForm.mobile = info.mobile || ""
      that.dataForm.sex = info.sex || ""
      that.dataForm.nationality = info.nationality || ""
      that.dataForm.nation = info.nation || ""
      that.dataForm.idNumber = info.idNumber || ""
      that.dataForm.birthday = info.birthday || ""
      that.dataForm.regAddress = info.regAddress || ""
      that.dataForm.nowAddress = info.nowAddress || ""
      that.dataForm.headImg = info.headImg || ""
      that.dataForm.company = info.company || ""
      that.show.nationalityStr = info.nationalityStr || "未知"
      that.show.nationStr = info.nationStr || "未知"
      that.show.idNumberTypeStr = info.idNumberTypeStr || "身份证"
      that.dataForm.idNumberType = info.idNumberType || "1"
      if (null != info.headImg) {
        that.show.headImg = info.headImg ? getImageStream(info.headImg) : getImageStream("files/wx/images/content/headImg.png")
      }
      that.dataForm.registryType = info.registryType + '' || ""
      that.dataForm.emMobile = info.emMobile || ""
      that.dataForm.emContact = info.emContact || ""
      that.show.registryTypeStr = info.registryTypeStr || "未知"
      this.dataForm.room = info.buildingRoom
      this.dataForm.rentStartDate = info.rentStartDate
      this.dataForm.rentFinishDate = info.rentFinishDate
      this.houseResId = info.houseResId
    },
    selectInfo() {
      this.userSelect();
      this.houseSelect();
      this.getDictList()
    },
    getDictList() {
      getVirtualDict('political', (dict) => {
        this.selectList.politicalList = dict
      })
      getVirtualDict('religious', (dict) => {
        this.selectList.religiousList = dict
      })
      getDictTree({ code: 'marriage' }, (dict) => {
        this.selectList.marriageList = dict
      })
      getDictTree({ code: 'education' }, (dict) => {
        this.selectList.educationList = dict
      })
    },
    userSelect() {
      //户籍性质
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({ data }) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          that.selectList.registryTypeList = data.registryTypes
        }
      })
      //身份证类型
      getVirtualDict("idNumberType", function (virtualDictList) {
        that.selectList.idNumberTypeList = virtualDictList
      })
    },
    houseSelect() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data.code == 0) {
          data.relationships.map((r) => {
            r.value = r.value + ""
          })
          that.selectList.relationshipList = data.relationships
        }
      })
    },
    headImgButton(e) {
      if (!that.control.disabled) {
        let path = "files/userHeadImg/temporary"
        this.$toast.loading({
          message: '上传中...',
          forbidClick: true,
          duration: 0,
          overlay: true
        })
        upload(e.file, path, null, function (res) {
          // alert(JSON.stringify(res))
          that.show.headImg = getImageStream(res.fileMessage.relativePath)
          that.dataForm.temporaryHeadImg = res.fileMessage.relativePath
          that.dataForm.temporaryHeadImgSuffix = res.fileMessage.suffix
          that.$toast.clear()
        })
      }
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/cardIdIdentified'),
        method: 'post',
        header: {
          appid: this.$appid
        },
        data: formdata
      }).then(({ data }) => {
        if (data.code == 0) {
          this.dataForm.userName = data.message.userName
          this.dataForm.idNumberType = 1
          this.dataForm.idNumber = data.message.id
          this.dataForm.regAddress = data.message.addr
          this.dataForm.birthday = data.message.birthday
          this.dataForm.sex = message.gender == "男" ? 1 : 2
          this.dataForm.nation = data.message.nation
          this.show.nation = data.message.nationality
          this.show.idNumberTypeStr = '身份证'
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    goNation() {
      if (!this.control.disabled) {
        this.control.nationShow = true
      }
    },
    goNationality() {
      if (!this.control.disabled) {
        this.control.nationalityShow = true
      }
    },
    showIdNumberType() {
      if (!this.control.disabled) {
        this.control.idNumberType = true
      }
    },
    idNumberType(e) {
      this.dataForm.idNumberType = parseInt(e.value)
      this.show.idNumberTypeStr = e.label
      this.control.idNumberType = false
    },
    spouseIdNumberTypeConfirm(e) {
      this.dataForm.spouseIdNumberType = parseInt(e.value)
      this.show.spouseIdNumberType = e.label
      this.control.spouseIdNumberType = false
    },
    showBirthday() {
      if (!this.control.disabled) {
        this.control.birthday = true
      }
    },
    birthdayCalendar(e) {
      let date = formatterDate(e)
      this.control.birthday = false
      this.dataForm.birthday = date

    },
    relationshipConfirm(e) {
      this.dataForm.relationship = e.value
      this.show.relationship = e.label
      this.control.relationship = false
    },
    showRegistryType() {
      if (!this.control.disabled) {
        this.control.registryType = true
      }
    },
    registryType(e) {
      this.dataForm.registryType = parseInt(e.value)
      this.show.registryTypeStr = e.label
      this.control.registryType = false
    },
    //编辑
    redact() {
      this.control.disabled = false
    },
    sexConfirm(value) {
      this.dataForm.sex = value.value
      this.control.sex = false
    },
    startDateChange(value) {
      let d = new Date(this.dataForm.rentFinishDate)
      if (this.dataForm.rentFinishDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.rentStartDate = formatterDate(value)
      this.control.rentStartDate = false

    },
    endDateChange(value) {
      let d = new Date(this.dataForm.rentStartDate)
      if (this.dataForm.rentStartDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.rentFinishDate = formatterDate(value)
      this.control.rentFinishDate = false
    },
    //提交
    onSubmit(e) {
      //校验身份证号
      this.$dialog.confirm({
        message: '确认提交？',
      }).then(() => {
        // //debugger
        let numberBool = IdentityCodeValid(that.dataForm.idNumber)
        if (numberBool == '身份证号格式错误' && that.dataForm.idNumberType == 1) {
          return that.$toast.fail(numberBool)
        }
        if (this.dataForm.userName.trim() == '') {
          return this.$toast.fail('请输入姓名')
        }
        if (this.dataForm.idNumber.trim() == '' && that.show.idNumberTypeStr != '无证件') {
          return this.$toast.fail('请输入有效证件号')
        }
        if (this.dataForm.mobile.trim() == '') {
          return this.$toast.fail('请输入手机号码')
        }
        if (this.show.registryTypeStr == '' || this.show.registryTypeStr == '请选择') {
          return this.$toast.fail('请选择户籍类型')
        }
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/building/house/buildingRes/update`),
            method: 'post',
            data: this.$http.adornData({
              id: this.houseResId,
              userId: this.dataForm.id,
              houseId: this.dataForm.houseId,
              relationship: this.dataForm.relationship,
              room: this.dataForm.room,
              rentStartDate: this.dataForm.rentStartDate,
              rentFinishDate: this.dataForm.rentFinishDate
            })
          }).then(({ data }) => {
            if (data.code == 0) {

            } else {
              this.$toast.fail(data.msg)
            }
          })
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/userInfo/update`),
            method: 'post',
            data: this.$http.adornData({
              id: this.dataForm.id,
              name: this.dataForm.userName,
              sex: this.dataForm.sex,
              nationality: this.dataForm.nationality,
              idNumber: this.dataForm.idNumber,
              mobile: this.dataForm.mobile,
              nation: this.dataForm.nation,
              birthday: this.dataForm.birthday,
              company: this.dataForm.company,
              regAddress: this.dataForm.regAddress,
              nowAddress: this.dataForm.nowAddress,
              emContact: this.dataForm.emContact,
              emMobile: this.dataForm.emMobile,
              orgId: this.dataForm.orgId,
              temporaryHeadImg: this.dataForm.temporaryHeadImg,
              temporaryHeadImgSuffix: this.dataForm.temporaryHeadImgSuffix,
              headImg: this.dataForm.temporaryHeadImg,
              rentStartDate: this.dataForm.rentStartDate,
              rentFinishDate: this.dataForm.rentFinishDate,
              registryType: this.dataForm.registryType,
              houseId: this.dataForm.houseId
            })
          }).then(({ data }) => {
            if (data.code == 0) {
              this.$toast.success({
                message: '编辑成功',
                duration: 3000,
                onOpened: () => {
                  setTimeout(() => {
                    this.$router.go(-1)
                  }, 1500)
                }
              })
            } else {
              this.$toast.fail(data.msg)
            }
          })
        } else {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/save`),
            method: 'post',
            data: this.$http.adornData({
              id: this.dataForm.id,
              name: this.dataForm.userName,
              sex: this.dataForm.sex,
              nationality: this.dataForm.nationality,
              idNumber: this.dataForm.idNumber,
              mobile: this.dataForm.mobile,
              nation: this.dataForm.nation,
              birthday: this.dataForm.birthday,
              company: this.dataForm.company,
              regAddress: this.dataForm.regAddress,
              nowAddress: this.dataForm.nowAddress,
              emContact: this.dataForm.emContact,
              emMobile: this.dataForm.emMobile,
              orgId: this.dataForm.orgId,
              jmggOrgId: this.dataForm.orgId,
              temporaryHeadImg: this.dataForm.temporaryHeadImg,
              temporaryHeadImgSuffix: this.dataForm.temporaryHeadImgSuffix,
              headImg: this.dataForm.temporaryHeadImg,
              death: 0,
              source: this.$userId,
              registryType: this.dataForm.registryType
            })
          }).then(({ data }) => {
            if (data.code == 0) {
              this.$http({
                url: this.$http.adornUrl(`/wxapp/building/house/jmgg/res/save`),
                method: 'post',
                data: this.$http.adornData({
                  userId: data.userId,
                  houseId: this.dataForm.houseId,
                  relationship: this.dataForm.relationship,
                  nowLive: 1,
                  moveOut: 0,
                  status: 0,
                  createUserId: this.$userId,
                  updateUserId: this.$userId
                })
              }).then(({ data }) => {
                if (data.code == 0) {
                  this.$toast.success({
                    message: '保存成功',
                    duration: 3000,
                    onOpened: () => {
                      setTimeout(() => {
                        this.$router.go(-1)
                      }, 1500)
                    }
                  })
                }
              })
            } else {
              that.$toast.fail(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.required {
  &::before {
    content: '*';
    color: red;
    position: absolute;
    left: 10px;
  }
}

.houseTypeDes {
  color: red;
  font-size: 24px;
  line-height: 26px;
  padding: 10px;
}

.content {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-top: -0.3rem;
  margin-bottom: -0.3rem;
  background-color: #f5f5f5;
}

::v-deep .van-uploader,
::v-deep .van-uploader__input-wrapper {
  width: 100%;
}

.headImg {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}

.van-cell {
  align-items: center;
}

::v-deep .van-collapse-item__content {
  padding: 0;
}

::v-deep .van-field__control--left {
  text-align: left !important;
}

.left>>>.van-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}

.btnPosition {
  position: absolute;
  bottom: 20px;
  width: 720px;
  left: 15px;
}

.ww {
  width: 100%;
}

.userRes {
  height: 160px;
}

.pagemtop {
  margin-top: 30px;
}

.goto {
  float: right;
  margin-left: 20px;
}

.blue {
  color: #007AFF
}

.houses {
  padding-top: 20px;
  background-color: #f5f5f5;
}

.addHouse {
  text-align: center;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.addHouseFont {
  margin-left: 4px;
  font-size: 30px;
  color: #409EFF
}

.iconSize {
  font-size: 200px;
}

.bai {
  height: 200px;
}

.mini-font-size {
  font-size: 25px;
}

.inspect {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 170px;
  height: 60px;
  line-height: 60px;
  width: 120px;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 9;
}
</style>
